import { Entry } from "~/common";

import { getRequestMethodFromHttpMethod } from "./utils";
import { fetchRequest } from "../utils/utils";

export * as EntryHttpClients from "./entry";

const { routes } = Entry.ENTRY_HTTP_CONFIG;

/** Http client for `Entry` features */
export const EntryHttpClient: Entry.EntryHttp = {
	findById: params =>
		fetchRequest({
			endpoint: routes.findById.path(params),
			method: getRequestMethodFromHttpMethod(routes.findById.method),
		}),
};
