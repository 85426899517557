import * as z from "zod";

import { recipeModelSchema } from "../recipe.model";
import { salaryDtoSchema } from "../salary/dtos/salary.dto";

/** Validation schema for {@link RecipeDto} */
export const recipeDtoSchema = recipeModelSchema.extend({
	// By default, sorted by start date (most recent first)
	salaries: z.array(salaryDtoSchema),
});
export type RecipeDto = z.infer<typeof recipeDtoSchema>;
