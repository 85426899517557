import { HttpRoute } from "@nna/core";

import { ContactDto, ContactQueryDto, ContactQueryResultsDto } from "./dtos";

/** Entrypoint route */
const root = HttpRoute.builder("contacts");
const rootById = root.addSegment({
	param: "contactId",
	type: "param",
	validation: "number",
});

/** HTTP configuration for `Contact` features */
export const CONTACT_HTTP_CONFIG = {
	routes: {
		findAndCount:
			root.get<
				(query: ContactQueryDto) => Promise<ContactQueryResultsDto>
			>(),
		findById: rootById.get<() => Promise<ContactDto>>(),
	} satisfies HttpRoute.Definitions,
} as const;

/** HTTP specification for the `Plan` feature */
export type ContactHttp = HttpRoute.Handlers<typeof CONTACT_HTTP_CONFIG.routes>;
