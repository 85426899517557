import {
	AuthLogin,
	AuthPasswordReset,
	AuthPasswordSet,
	AuthProfile,
	AuthRefresh,
	AuthSuccess,
} from "~/common/auth";

import { AuthHttpClient } from "../clients";
import {
	CustomUseMutationOptions,
	CustomUseQueryOptions,
} from "../reactQueryConfig";
import { FetchError } from "../utils/FetchError";

const client = AuthHttpClient;

export const authRepository = {
	getProfile: (): CustomUseQueryOptions<AuthProfile.Dto, FetchError> => ({
		queryFn: () => client.getProfile(),
		queryKey: ["user", "expireOn", "issuedAt"],
	}),
	login: (): CustomUseMutationOptions<
		AuthSuccess.Dto,
		FetchError,
		AuthLogin.Dto
	> => ({
		mutationFn: body => client.login(body),
		mutationKey: [],
	}),
	logout: (): CustomUseMutationOptions<void, FetchError, void> => ({
		mutationFn: () => client.logout(),
		mutationKey: [],
	}),
	passwordReset: (): CustomUseMutationOptions<
		void,
		FetchError,
		AuthPasswordReset.Dto
	> => ({
		mutationFn: body => client.passwordReset(body),
		mutationKey: [],
	}),
	passwordSet: (): CustomUseMutationOptions<
		void,
		FetchError,
		AuthPasswordSet.Dto
	> => ({
		mutationFn: body => client.passwordSet(body),
		mutationKey: [],
	}),
	refresh: (): CustomUseMutationOptions<
		AuthSuccess.Dto,
		FetchError,
		AuthRefresh.Dto
	> => ({
		mutationFn: body => client.refresh(body),
		mutationKey: [],
	}),
};
