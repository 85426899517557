import { Model } from "@nna/core";
import * as z from "zod";

const base = Model.schema;

/** Validation schema for {@link EntryModel} */
export const entryModelSchema = base.extend({
	// for V1
	// fkParent: base.shape._id
	// 	.nullable()
	// 	.describe("Foreign key to its parent entry"),
});
/** A entry is the "input-configuration" of a plan  */
export type EntryModel = z.infer<typeof entryModelSchema>;
