import { HttpRoute } from "@nna/core";

import { type EntryHttpConfig } from "../entry.http";
import type {
	PersonalCreateDto,
	PersonalDto,
	PersonalQueryDto,
	PersonalQueryResultsDto,
	PersonalUpdateDto,
} from "./dtos";

/**
 * Create routes definitions for `Personal` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createPersonalHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "personalId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(
						query: PersonalQueryDto,
					) => Promise<PersonalQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<PersonalDto>>(),

			create: base.post<
				(body: PersonalCreateDto) => Promise<PersonalDto>
			>(),
			delete: baseById.delete<() => Promise<PersonalDto>>(),
			update: baseById.patch<
				(body: PersonalUpdateDto) => Promise<PersonalDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Personal` features and children */
export type PersonalHttpConfig = EntryHttpConfig["children"]["personals"];
/** HTTP specification for the `Entry/Personal` features */
export type PersonalHttp = HttpRoute.Handlers<PersonalHttpConfig["routes"]>;
