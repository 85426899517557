import { createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { salaryDtoSchema } from "./salary.dto";

/** Validation schema for {@link SalaryQueryResultsDto} */
export const salaryQueryResultsDtoSchema =
	createQueryResultsSchema(salaryDtoSchema);
/** Results for `salary` from a query */
export type SalaryQueryResultsDto = z.infer<typeof salaryQueryResultsDtoSchema>;
