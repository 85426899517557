import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	currentExpenseModelRawShape,
	currentExpenseModelSchema,
} from "../current.expense.model";

/** Validation schema for {@link CurrentExpenseDto} */
export const currentExpenseDtoSchema = currentExpenseModelSchema;
export type CurrentExpenseDto = z.infer<typeof currentExpenseDtoSchema>;

/** Validation schema for {@link CurrentExpenseCreateDto} */
export const currentExpenseCreateDtoSchema = z
	.object(currentExpenseModelRawShape)
	.strict();
/** Dto to create a `recipe */
export type CurrentExpenseCreateDto = z.infer<
	typeof currentExpenseCreateDtoSchema
>;

/** Validation schema for {@link CurrentExpenseQueryDto} */
export const currentExpenseQueryDtoSchema = createQueryObjectSchema(
	currentExpenseDtoSchema,
	{
		coerce: true,
		strict: true,
	},
).strict();

/** DTO used to filter `currentExpense` */
export type CurrentExpenseQueryDto = z.infer<
	typeof currentExpenseQueryDtoSchema
>;

/** Validation schema for {@link CurrentExpenseQueryResultsDto} */
export const currentExpenseQueryResultsDtoSchema = createQueryResultsSchema(
	currentExpenseDtoSchema,
);
/** Results for `currentExpenses` from a query */
export type CurrentExpenseQueryResultsDto = z.infer<
	typeof currentExpenseQueryResultsDtoSchema
>;

/** Validation schema for {@link CurrentExpenseUpdateDto} */
export const currentExpenseUpdateDtoSchema = z
	.object(currentExpenseModelRawShape)
	.strict()
	.partial();
/** Dto to update a `currentExpense */
export type CurrentExpenseUpdateDto = z.infer<
	typeof currentExpenseUpdateDtoSchema
>;
