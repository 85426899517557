import { HttpRoute } from "@nna/core";

import { type EntryHttpConfig } from "../entry.http";
import type {
	RecipeCreateDto,
	RecipeDto,
	RecipeQueryDto,
	RecipeQueryResultsDto,
	RecipeUpdateDto,
} from "./dtos";
import { createSalaryHttpDefinitions } from "./salary/salary.http";

/**
 * Create routes definitions for `Recipe` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createRecipeHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "recipeId",
		type: "param",
		validation: "number",
	});

	return {
		children: {
			salaries: createSalaryHttpDefinitions(
				baseById.addSegment("salaries"),
			),
		},
		routes: {
			findAndCount:
				base.get<
					(query: RecipeQueryDto) => Promise<RecipeQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<RecipeDto>>(),

			create: base.post<(body: RecipeCreateDto) => Promise<RecipeDto>>(),
			delete: baseById.delete<() => Promise<RecipeDto>>(),
			update: baseById.patch<
				(body: RecipeUpdateDto) => Promise<RecipeDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Recipe` features and children */
export type RecipeHttpConfig = EntryHttpConfig["children"]["recipes"];
/** HTTP specification for the `Entry/Recipe` features */
export type RecipeHttp = HttpRoute.Handlers<RecipeHttpConfig["routes"]>;
