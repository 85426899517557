import { Model } from "@nna/core";
import * as z from "zod";

import { customerModelSchema } from "../customer/customer.model";
import { userModelSchema } from "../user/user.model";

/** Constraints applied to {@link planModelSchema} */
export const PLAN_CONSTRAINTS = {
	observationTime: { gt: 0, lt: 200 },
	year: { gt: 1999, lt: 2500 },
} satisfies Record<string, unknown>;

/** Validation schema for {@link PlanModel} */
export const planModelSchema = Model.schema.extend({
	fkCounselor: userModelSchema.shape._id.describe(
		"Foreign key ot the counselor (user)",
	),
	// Only one FK => A "one-person-plan"
	//	2 => "couple-plan"
	fkCustomer: customerModelSchema.shape._id,
	fkCustomer2: customerModelSchema.shape._id.nullable(),

	observationTime: z
		.number()
		.int()
		.gt(PLAN_CONSTRAINTS.observationTime.gt)
		.lt(PLAN_CONSTRAINTS.observationTime.lt)
		.describe("The time period (in year) that the plan will be observed"),
	year: z
		.number()
		.int()
		.gt(PLAN_CONSTRAINTS.year.gt)
		.lt(PLAN_CONSTRAINTS.year.lt)
		.describe("The year of the plan"),
});
/** A financial plan */
export type PlanModel = z.infer<typeof planModelSchema>;
