import * as joy from "@mui/joy";
import { ReactNode } from "react";

import { Icon, IconName } from "../../ui-atoms/components/Icons/Icon";
import { textBody } from "../../ui-layout/styles/textStyles";
import { theme } from "../../ui-layout/styles/theme";

// TODO: there is some cleanup left for properly mapping joy props to formik (with Picks etc.)

/** Props for {@link Checkbox} */
export interface CheckboxProps
	extends Pick<
		joy.CheckboxProps,
		"disabled" | "name" | "onBlur" | "onChange"
	> {
	/** The label displayed on the right of the checkbox */
	label?: ReactNode;
	/** The checkbox value */
	value?: number | string | readonly string[];
}

/**
 * Checkbox component
 *
 * @param props to generate the component
 */
export const Checkbox = (props: CheckboxProps): JSX.Element => {
	const { name, value, ...rest } = props;

	// Formik pass down `value`, but joy expects `checked` to reflect the checkbox state
	const checked = Array.isArray(value) ? 0 < value.length : !!value;

	return (
		<CheckboxWrapper
			checked={checked}
			checkedIcon={<Icon name={IconName.checkmark} />}
			id={name}
			name={name}
			{...rest}
		/>
	);
};

const { checkboxClasses } = joy;
const CheckboxWrapper = joy.styled(joy.Checkbox)<{ disabled?: boolean }>`
	gap: 4px;
	align-items: center;

	/** Label */
	& .${checkboxClasses.label} {
		padding-top: 1px;
		color: ${({ disabled }) => (disabled ? theme.vars.palette.grey[45] : theme.vars.palette.grey[90])};
		${textBody}
	}

	/** Unchecked */
	& .${checkboxClasses.checkbox} {
		background-color: ${theme.vars.palette.grey[30]};
		border: none;
		border-radius: 2px;

		:hover {
			background-color: ${theme.vars.palette.grey[30]};
		}
	}

	/** Checked */
	& .${checkboxClasses.checkbox}.${checkboxClasses.checked} {
		background-color: ${theme.vars.palette.blue.primary};
	}

	/** Disabled */
	& .${checkboxClasses.disabled} .${checkboxClasses.input} {
		cursor: not-allowed !important;
		pointer-events: all !important;
	}

	/** Disabled - unchecked */
	& .${checkboxClasses.disabled} {
		background-color: ${theme.vars.palette.grey[45]};

		:hover {
			background-color: ${theme.vars.palette.grey[45]};
		}
	}

	/** Disabled - checked */
	& .${checkboxClasses.disabled}.${checkboxClasses.checked} {
		background-color: ${theme.vars.palette.grey[45]};
		color: ${theme.vars.palette.white.primary};
	}
`;
