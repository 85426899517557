import { ModelBase } from "@nna/core";

import { Customer, CustomerSingle } from "./customer";

/** Minimal structure of a plan with customer data */
export interface PlanWithCustomers {
	customer: ModelBase;
	customer2: ModelBase | null;
}
/**
 * Select the customer(s) from the plan given a selector.
 * As mentioned in {@link Customer}, only the 'customer1' is returned when there is no 'customer2'.
 *
 * @param plan to select the customers from
 * @param selector of the customer(s) to return
 * @returns the customer(s) given the selector
 */
export function selectCustomers<T extends PlanWithCustomers>(
	plan: T,
	selector: Customer,
): [T["customer"], T["customer"]] | [T["customer"]] {
	const { customer, customer2 } = plan;
	if (customer2 === null) {
		return [customer];
	}

	switch (selector) {
		case "CUSTOMER_1":
			return [customer];
		case "CUSTOMER_2":
			return [customer2];
		case "BOTH":
			return [customer, customer2];
	}
}

/**
 * Same as {@link selectCustomers} but only for a selector for a single customer.
 *
 * @see selectCustomers
 * @param plan to select the customers from
 * @param selector of the customer to return
 * @returns the customer given the selector
 */
export function selectCustomerSingle<T extends PlanWithCustomers>(
	plan: T,
	selector: CustomerSingle,
) {
	return selectCustomers<T>(plan, selector)[0];
}

/**
 * Returns the relevant selector that selects all customers, depending on if there is 1 or 2 customers
 *
 * @param plan to select the customers from
 * @returns the customer selector
 */
export function getAllCustomersSelector<T extends PlanWithCustomers>(
	plan: T,
): Customer {
	return plan.customer2 ? "BOTH" : "CUSTOMER_1";
}
