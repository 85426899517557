import { HttpRoute } from "@nna/core";

import type {
	ChildCreateDto,
	ChildDto,
	ChildQueryDto,
	ChildQueryResultsDto,
	ChildUpdateDto,
} from "./dtos";
import { EntryHttpConfig } from "../../entry.http";

/**
 * Create routes definitions for `child` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createChildHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "childId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(query: ChildQueryDto) => Promise<ChildQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<ChildDto>>(),

			create: base.post<(body: ChildCreateDto) => Promise<ChildDto>>(),
			delete: baseById.delete<() => Promise<ChildDto>>(),
			update: baseById.patch<
				(body: ChildUpdateDto) => Promise<ChildDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Personal/Child` features and children */
type ChildHttpConfig = EntryHttpConfig["children"]["children"];
/** HTTP specification for the `Entry/Personal/Child` features */
export type ChildHttp = HttpRoute.Handlers<ChildHttpConfig["routes"]>;
