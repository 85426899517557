import { Model } from "@nna/core";
import * as z from "zod";

import { createPeopleContactSchema, createPeoplePhoneSchema } from "../people";
import { userModelSchema } from "../user";

const customerContactDataSchema = createPeopleContactSchema({
	address: { min: 2 },
	email: { allowEmpty: false },
	name: { min: 2 },
	phone: { allowEmpty: false },
}).omit({ company: true });

/** Constraints applied to {@link planModelSchema} */
export const CUSTOMER_CONSTRAINTS = {
	birthday: { gte: new Date(1900, 0, 1), lte: new Date(2200, 0, 1) },
} satisfies Record<string, unknown>;

/** Validation schema for {@link CustomerModel} */
export const customerModelSchema = Model.schema.extend({
	...customerContactDataSchema.shape,
	fkCounselor: userModelSchema.shape._id.describe(
		"Foreign key ot the counselor (user)",
	),

	birthday: z
		.date()
		.min(CUSTOMER_CONSTRAINTS.birthday.gte)
		.max(CUSTOMER_CONSTRAINTS.birthday.lte)
		.describe("Birthday of a person"),
	comment: z.string().describe("Comment for the customer"),
	phoneSecondary: createPeoplePhoneSchema({ allowEmpty: true }).describe(
		"Additional phone number",
	),
	procuration: z.boolean(),
	recommendation: createPeopleContactSchema().describe(
		"People that recommended this customer to join the application",
	),
});
/**
 * A customer is a 'client' of the application, someone with data/money/plans/...
 * Note: Not named 'client' to avoid confusion in the code
 *
 * FG-11 Contacts are a relation
 */
export type CustomerModel = z.infer<typeof customerModelSchema>;
export type CustomerModelRecommendation = CustomerModel["recommendation"];
