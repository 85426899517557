import { createQueryObjectSchema } from "@nna/core";
import * as z from "zod";

import { salaryDtoSchema } from "./salary.dto";

/** Validation schema for {@link SalaryQueryDto} */
export const salaryQueryDtoSchema = createQueryObjectSchema(salaryDtoSchema, {
	coerce: true,
	strict: true,
}).strict();

/** DTO used to filter `salary` */
export type SalaryQueryDto = z.infer<typeof salaryQueryDtoSchema>;
