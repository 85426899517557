import { createQueryObjectSchema } from "@nna/core";
import * as z from "zod";

import { recipeDtoSchema } from "./recipe.dto";

/** Validation schema for {@link RecipeQueryDto} */
export const recipeQueryDtoSchema = createQueryObjectSchema(recipeDtoSchema, {
	coerce: true,
	strict: true,
}).strict();

/** DTO used to filter `recipe` */
export type RecipeQueryDto = z.infer<typeof recipeQueryDtoSchema>;
