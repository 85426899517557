import { HttpRoute } from "@nna/core";

import {
	ExceptionalExpenseCreateDto,
	ExceptionalExpenseDto,
	ExceptionalExpenseQueryDto,
	ExceptionalExpenseQueryResultsDto,
	ExceptionalExpenseUpdateDto,
} from "./dto";
import { EntryHttpConfig } from "../../../entry.http";

/**
 * Create routes definitions for `exceptional` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function CreateExceptionalExpensesHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "expenseId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(
						query: ExceptionalExpenseQueryDto,
					) => Promise<ExceptionalExpenseQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<ExceptionalExpenseDto>>(),

			create: base.post<
				(
					body: ExceptionalExpenseCreateDto,
				) => Promise<ExceptionalExpenseDto>
			>(),
			delete: baseById.delete<() => Promise<ExceptionalExpenseDto>>(),
			update: baseById.patch<
				(
					body: ExceptionalExpenseUpdateDto,
				) => Promise<ExceptionalExpenseDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}
/** Full configuration for `Entry/ExceptionalExpense` features and children */
type ExceptionalExpenseHttpConfig =
	EntryHttpConfig["children"]["exceptional"]["children"]["expenses"];
/** HTTP specification for the `Entry/ExceptionalExpense` features */
export type ExceptionalExpenseHttp = HttpRoute.Handlers<
	ExceptionalExpenseHttpConfig["routes"]
>;
