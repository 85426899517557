import { styled } from "@mui/joy";
import { HTMLAttributes } from "react";

import { PeopleAvatar, PeopleAvatarProps } from "../../people/components";
import { Checkbox } from "../../ui-form/components/Checkbox";
import { textH4 } from "../styles/textStyles";

/** Props for {@link SectionTitle} */
export interface SectionTitleProps
	extends Partial<Pick<PeopleAvatarProps, "name">>,
		Pick<HTMLAttributes<HTMLDivElement>, "className"> {
	/** Actions (typically an array of Buttons) displayed at the right of the title bar */
	actions?: React.ReactNode;
	/**
	 * Whether to display the checkbox at the left of the title bar or not
	 *
	 * @default false
	 */
	showActivation?: boolean;
	/** The section title */
	title: string;
}

/**
 * A section title (level 3)
 *
 * @param props to generate the component
 */
export const SectionTitle = (props: SectionTitleProps) => {
	const {
		actions,
		name: avatarName,
		showActivation = false,
		title,
		...rootProps
	} = props;

	return (
		<SectionTitleContainer {...rootProps}>
			{showActivation && <Checkbox />}
			{avatarName && <PeopleAvatar name={avatarName} />}
			<h4 css={textH4}>{title}</h4>
			<ActionsContainer>{actions}</ActionsContainer>
		</SectionTitleContainer>
	);
};

const SectionTitleContainer = styled("div")`
	align-items: center;
	border-bottom: 1px solid ${({ theme }) => theme.palette.grey[40]};
	display: flex;
	gap: 4px;
	padding: 30px 12px 12px;
`;

const ActionsContainer = styled("div")`
	display: flex;
	flex-wrap: no-wrap;
	gap: 8px;
	margin-left: auto;
`;
