import { HttpRoute } from "@nna/core";

import {
	CurrentExpenseCreateDto,
	CurrentExpenseDto,
	CurrentExpenseQueryDto,
	CurrentExpenseQueryResultsDto,
	CurrentExpenseUpdateDto,
} from "./dto";
import { EntryHttpConfig } from "../../../entry.http";

/**
 * Create routes definitions for `current` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function CreateCurrentExpensesHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "expenseId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(
						query: CurrentExpenseQueryDto,
					) => Promise<CurrentExpenseQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<CurrentExpenseDto>>(),

			create: base.post<
				(body: CurrentExpenseCreateDto) => Promise<CurrentExpenseDto>
			>(),
			delete: baseById.delete<() => Promise<CurrentExpenseDto>>(),
			update: baseById.patch<
				(body: CurrentExpenseUpdateDto) => Promise<CurrentExpenseDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}
/** Full configuration for `Entry/CurrentExpense` features and children */
type CurrentExpenseHttpConfig =
	EntryHttpConfig["children"]["current"]["children"]["expenses"];
/** HTTP specification for the `Entry/CurrentExpense` features */
export type CurrentExpenseHttp = HttpRoute.Handlers<
	CurrentExpenseHttpConfig["routes"]
>;
