import { HttpRoute } from "@nna/core";

import type {
	ResidenceCreateDto,
	ResidenceDto,
	ResidenceQueryDto,
	ResidenceQueryResultsDto,
	ResidenceUpdateDto,
} from "./dtos";
import { EntryHttpConfig } from "../../entry.http";

/**
 * Create routes definitions for `residence` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createResidenceHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "residenceId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(
						query: ResidenceQueryDto,
					) => Promise<ResidenceQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<ResidenceDto>>(),

			create: base.post<
				(body: ResidenceCreateDto) => Promise<ResidenceDto>
			>(),
			delete: baseById.delete<() => Promise<ResidenceDto>>(),
			update: baseById.patch<
				(body: ResidenceUpdateDto) => Promise<ResidenceDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Personal/Residence` features and children */
type ResidenceHttpConfig = EntryHttpConfig["children"]["residences"];
/** HTTP specification for the `Entry/Personal/Residence` features */
export type ResidenceHttp = HttpRoute.Handlers<ResidenceHttpConfig["routes"]>;
