import * as z from "zod";

/** The discrimination key for {@link Model} */
export const DISCRIMINATION_KEY = "type";

/** @internal */
function createSchema<T extends string, S extends z.ZodRawShape>(
	type: T,
	shape: S,
) {
	return z.object({ ...shape, [DISCRIMINATION_KEY]: z.literal(type) });
}

/** Validation schema for {@link StandardOption} */
export const standardOptionSchema = z.enum([
	"SALARY",
	"BONUS",
	"PROFIT",
	"1ST PILLAR PENSION",
	"2ND PILLAR PENSION",
	"3RD PILLAR PENSION",
	"INCIDENTAL EARNINGS",
	"PENSION",
]);
/** The possible values for {@link StandardModel} */
export type StandardOption = z.infer<typeof standardOptionSchema>;

/** The discriminated type for {@link StandardModel} */
export const STANDARD_TYPE = "standard";
/** Validation schema for {@link StandardModel} */
export const standardSchema = createSchema(STANDARD_TYPE, {
	option: standardOptionSchema,
});
/** The {@link Model} with `standard` type */
export type StandardModel = z.infer<typeof standardSchema>;

/** The discriminated type for {@link CustomModel} */
export const CUSTOM_TYPE = "custom";
/** Validation schema for {@link CustomModel} */
export const customSchema = createSchema(CUSTOM_TYPE, {
	custom: z.string(),
});
/** The {@link Model} with `custom` type */
export type CustomModel = z.infer<typeof customSchema>;

/** Validation schema for evolution param in {@link SalaryModel} */
export const schema = z.discriminatedUnion(DISCRIMINATION_KEY, [
	standardSchema,
	customSchema,
]);
export type Model = z.infer<typeof schema>;

/** Known type for {@link Model} */
export type Type = Model[typeof DISCRIMINATION_KEY];
/** List of available types for {@link Model} */
export const TYPES: readonly Type[] = schema.options.map(
	({ shape }) => shape[DISCRIMINATION_KEY].value,
);
