import { Model } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../../entry.change-state";
import { SalaryEvolution } from "../../../recipe/salary";
import { exceptionalModelSchema } from "../exceptional.model";

/** Zod Shape for {@link exceptionalExpenseModelSchema} */
export const exceptionalExpenseModelRawShape = {
	amountParam: SalaryEvolution.schema.describe("Amount or Percent"),
	label: z.string(),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link ExpenseModel} */
export const exceptionalExpenseModelSchema = Model.schema.extend(
	addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(exceptionalExpenseModelRawShape),
		{
			...exceptionalExpenseModelRawShape,
			fkExceptional: exceptionalModelSchema.shape._id,
		},
	),
);

export type ExceptionalExpenseModel = z.infer<
	typeof exceptionalExpenseModelSchema
>;
