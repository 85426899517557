import * as z from "zod";

import { EntryCommon } from "../../../entry.common";

/** The discrimination key for {@link Model} */
export const DISCRIMINATION_KEY = "type";

/** @internal */
function createSchema<T extends string, S extends z.ZodRawShape>(
	type: T,
	shape: S,
) {
	return z.object({ ...shape, [DISCRIMINATION_KEY]: z.literal(type) });
}

/** The discriminated type for {@link AmountModel} */
export const AMOUNT_TYPE = "amount";
/** Validation schema for {@link AmountModel} */
export const amountSchema = createSchema(AMOUNT_TYPE, {
	amount: EntryCommon.amountSchema,
});
/** The {@link Model} with `amount` type */
export type AmountModel = z.infer<typeof amountSchema>;

/** The discriminated type for {@link PercentModel} */
export const PERCENT_TYPE = "percent";
/** Validation schema for {@link PercentModel} */
export const percentSchema = createSchema(PERCENT_TYPE, {
	percent: EntryCommon.percentageUnboundedSchema,
});
/** The {@link Model} with `amount` type */
export type PercentModel = z.infer<typeof percentSchema>;

/** Validation schema for evolution param in {@link SalaryModel} */
export const schema = z.discriminatedUnion(DISCRIMINATION_KEY, [
	amountSchema,
	percentSchema,
]);
export type Model = z.infer<typeof schema>;

/** Known type for {@link Model} */
export type Type = Model[typeof DISCRIMINATION_KEY];
/** List of available types for {@link Model} */
export const TYPES: readonly Type[] = schema.options.map(
	({ shape }) => shape[DISCRIMINATION_KEY].value,
);
