import { Model } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../../entry.change-state";
import { SalaryEvolution } from "../../../recipe/salary";
import { currentModelSchema } from "../current.model";

/** Zod Shape for {@link currentExpenseModelSchema} */
export const currentExpenseModelRawShape = {
	amountParam: SalaryEvolution.schema.describe("Amount or Percent"),
	label: z.string(),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link ExpenseModel} */
export const currentExpenseModelSchema = Model.schema.extend(
	addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(currentExpenseModelRawShape),
		{
			...currentExpenseModelRawShape,
			fkCurrent: currentModelSchema.shape._id,
		},
	),
);

export type CurrentExpenseModel = z.infer<typeof currentExpenseModelSchema>;
