import { createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { recipeDtoSchema } from "./recipe.dto";

/** Validation schema for {@link RecipeQueryResultsDto} */
export const recipeQueryResultsDtoSchema =
	createQueryResultsSchema(recipeDtoSchema);
/** Results for `recipes` from a query */
export type RecipeQueryResultsDto = z.infer<typeof recipeQueryResultsDtoSchema>;
