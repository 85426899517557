import {
	CircularProgress,
	CircularProgressProps,
	Theme,
	circularProgressClasses,
} from "@mui/joy";

/**
 * Spinner component
 *
 * @param props per CircularProgressProps from Joy
 * @returns JSX.Element
 */
export const Spinner = (props: CircularProgressProps): JSX.Element => (
	<CircularProgress
		variant="soft"
		{...props}
		sx={theme => getSpinnerStyle(theme)}
	/>
);

function getSpinnerStyle(theme: Theme) {
	return {
		[`& .${circularProgressClasses.track}`]: {
			stroke: theme.vars.palette.grey[20],
		},
		[`& .${circularProgressClasses.progress}`]: {
			stroke: theme.vars.palette.blue.primary,
		},
	};
}
