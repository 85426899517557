import { Model } from "@nna/core";
import * as z from "zod";

import { createPeopleContactSchema } from "../people";

/** Validation schema for {@link ContactModel} */
export const contactModelSchema = Model.schema.extend(
	// Almost every field is "nullable" (empty string)
	createPeopleContactSchema({ name: { min: { first: 2, last: 0 } } }).shape,
);
export type ContactModel = z.infer<typeof contactModelSchema>;
