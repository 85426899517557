import { Paths } from "type-fest";
import * as z from "zod";

import * as ChangeState from "./change-state";

// "Proxy" (reduced index.ts)
export * as ChangeState from "./change-state";
export type { Model as ChangeStateModel } from "./change-state";

/** Proxy to {@link ChangeState.createSchema} */
export const createChangeStateSchema = ChangeState.createSchema;

/**
 * Creates a {@link ChangeState} validation schema from a referential zod Shape and its fields
 *
 * @param shape to select the fields with which the schema should be created from
 * @param fields to create the schema with
 * @returns validation schema
 */
export function createChangeStateSchemaWithShapeFields<
	const T extends z.ZodRawShape,
	const K extends ReadonlyArray<
		Extract<Paths<z.infer<z.ZodObject<T>>>, string>
	>,
>(shape: T, fields: K) {
	// `shape` is here for the type constraint (and for later use)
	return createChangeStateSchema<K>(fields);
}

/**
 * Creates a {@link ChangeState} validation schema from a zod Shape
 * /!\ Attention, it only takes the first level keys
 *
 * @param shape to create the schema from
 * @returns validation schema
 */
export function createChangeStateSchemaFromShape<const T extends z.ZodRawShape>(
	shape: T,
) {
	return createChangeStateSchemaWithShapeFields(
		shape,
		Object.keys(shape) as Array<
			Extract<
				Paths<z.infer<z.ZodObject<T>>, { maxRecursionDepth: 0 }>,
				string
			>
		>,
	);
}

/**
 * Creates a V0 {@link ChangeState} validation schema.
 *
 * To be later replaced by {@link createChangeStateSchemaWithShapeFields}.
 *
 * @param shape not used in v0
 * @param fields not used in v0
 * @returns validation schema
 */
export function createChangeStateSchemaWithShapeFieldsV0<
	const T extends z.ZodRawShape,
	const K extends ReadonlyArray<
		Extract<Paths<z.infer<z.ZodObject<T>>>, string>
	>,
>(shape: T, fields: K) {
	return ChangeState.v0.createSchema<K>(fields);
}

/**
 * Creates a V0 {@link ChangeState} validation schema.
 *
 * To be later replaced by {@link createChangeStateSchemaFromShape}.
 *
 * @param shape not used in v0
 * @returns validation schema
 */
export function createChangeStateSchemaFromShapeV0<
	const T extends z.ZodRawShape,
>(shape: T) {
	return createChangeStateSchemaWithShapeFieldsV0(
		shape,
		Object.keys(shape) as Array<
			Extract<
				Paths<z.infer<z.ZodObject<T>>, { maxRecursionDepth: 0 }>,
				string
			>
		>,
	);
}

/** Any {@link ChangeState} schema */
export type ChangeStateAnySchema =
	| ChangeState.Schema<readonly string[]>
	| ChangeState.v0.Schema<readonly string[]>;

/** A shared format of a zod shape with the `changeState` property */
export type ChangeStateSchemaWithinShape<
	Schema extends ChangeStateAnySchema,
	Shape extends z.ZodRawShape,
> = Shape & {
	changeState: Schema;
};

/**
 * Add a given `ChangeState` schema to a zod shape
 *
 * @param schema of the `changeState` to add
 * @param shape to add the schema to
 * @returns extended shape with the schema
 */
export function addChangeStateSchemaToShape<
	const Schema extends ChangeStateAnySchema,
	const Shape extends z.ZodRawShape,
>(schema: Schema, shape: Shape) {
	return {
		...shape,
		changeState: schema,
	} as const satisfies ChangeStateSchemaWithinShape<Schema, Shape>;
}

/** Object with a {@link ChangeState} property */
export type WithChangeState<
	Schema extends ChangeStateAnySchema = ChangeStateAnySchema,
> = z.infer<
	z.ZodObject<ChangeStateSchemaWithinShape<Schema, Record<never, never>>>
>;
