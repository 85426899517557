import { Model } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { entryModelSchema } from "../../entry.model";

/** Zod Shape for {@link exceptionalModelSchema} */
export const exceptionalModelRawShape = {} as const satisfies z.ZodRawShape;

/** Validation schema for {@link ExceptionalModel} */
export const exceptionalModelSchema = Model.schema.extend(
	addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(exceptionalModelRawShape),
		{
			...exceptionalModelRawShape,

			fkEntry: entryModelSchema.shape._id,
		},
	),
);

export type ExceptionalModel = z.infer<typeof exceptionalModelSchema>;
