import { Model } from "@nna/core";
import * as z from "zod";

import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { entryModelSchema } from "../../entry.model";
import { SalaryEvolution } from "../../recipe/salary";

/** Zod Shape for {@link currentModelSchema} */
export const currentModelRawShape = {
	date: z.date(),

	livingCostParam: SalaryEvolution.schema.describe("Amount or Percent"),

	marginParam: SalaryEvolution.schema.describe("Amount or Percent"),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link CurrentModel} */
export const currentModelSchema = Model.schema.extend(
	addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(currentModelRawShape),
		{
			...currentModelRawShape,

			fkEntry: entryModelSchema.shape._id,
		},
	),
);

export type CurrentModel = z.infer<typeof currentModelSchema>;
