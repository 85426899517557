import { Model } from "@nna/core";
import * as z from "zod";

import { createPeopleAddressSchema } from "../../../people";
import {
	addChangeStateSchemaToShape,
	createChangeStateSchemaFromShapeV0,
} from "../../entry.change-state";
import { EntryCommon } from "../../entry.common";
import { entryModelSchema } from "../../entry.model";

/** Zod Shape for {@link residenceModelSchema} */
export const residenceModelRawShape = {
	address: createPeopleAddressSchema({ min: 2 }), // the address is mandatory
	from: z.date().describe("At this residence from"),
	ofs_number: z.number().nullable(),
	resident: EntryCommon.customerSchema.describe(
		"The customer owner of the residence",
	),
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link ResidenceModel} */
export const residenceModelSchema = Model.schema.extend(
	addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(residenceModelRawShape),
		{
			...residenceModelRawShape,
			fkEntry: entryModelSchema.shape._id,
		},
	),
);

export type ResidenceModel = z.infer<typeof residenceModelSchema>;
