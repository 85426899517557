import { RecipeKind } from "../recipe-kind";

/**
 * Determines if there is a "distinction" between net & gross values for a recipe and its salaries.
 *
 * Note: this means that when `false`, only one value is necessary.
 * 	It does not mean that one of the field disappear.
 *
 * @param kind to determine the "amount types" from
 * @returns `false` when there is only 1 amount
 */
export function hasAmountNetAndGross(kind: RecipeKind.Model): boolean {
	if (kind.type === "custom") {
		// Custom is considered as 'SALARY'
		// https://apptitudestudio.atlassian.net/browse/FG-307
		return hasAmountNetAndGross({ option: "SALARY", type: "standard" });
	}

	switch (kind.option) {
		case "SALARY":
		case "BONUS":
		case "PROFIT":
			return true;

		case "1ST PILLAR PENSION":
		case "2ND PILLAR PENSION":
		case "3RD PILLAR PENSION":
		case "INCIDENTAL EARNINGS":
		case "PENSION":
			return false;
	}
}

/**
 * Determines if the expenses from the 'salaries' of a recipe should be considered.
 *
 * Note: It does not remove them, only ignored them (visually at least)
 *
 * @param kind to determine is the expenses can be ignored the from
 * @returns `false` when the expense can be ignored
 */
export function hasExpenses(kind: RecipeKind.Model): boolean {
	if (kind.type === "custom") {
		// Same as above
		return hasExpenses({ option: "SALARY", type: "standard" });
	}

	switch (kind.option) {
		case "SALARY":
		case "INCIDENTAL EARNINGS":
		case "BONUS":
			return true;

		case "PROFIT":
		case "1ST PILLAR PENSION":
		case "2ND PILLAR PENSION":
		case "3RD PILLAR PENSION":
		case "PENSION":
			return false;
	}
}
