import { HttpRoute } from "@nna/core";

import { type RecipeHttpConfig } from "../recipe.http";
import type {
	SalaryCreateDto,
	SalaryDto,
	SalaryQueryDto,
	SalaryQueryResultsDto,
	SalaryUpdateDto,
} from "./dtos";

/**
 * Create routes definitions for `salary` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createSalaryHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "salaryId",
		type: "param",
		validation: "number",
	});

	return {
		children: {},
		routes: {
			findAndCount:
				base.get<
					(query: SalaryQueryDto) => Promise<SalaryQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<SalaryDto>>(),

			create: base.post<(body: SalaryCreateDto) => Promise<SalaryDto>>(),
			delete: baseById.delete<() => Promise<SalaryDto>>(),
			update: baseById.patch<
				(body: SalaryUpdateDto) => Promise<SalaryDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}

/** Full configuration for `Entry/Recipe/Salary` features and children */
type SalaryConfig = RecipeHttpConfig["children"]["salaries"];
/** HTTP specification for the `Entry/Recipe/Salary` features */
export type SalaryHttp = HttpRoute.Handlers<SalaryConfig["routes"]>;
