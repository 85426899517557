import { Model } from "@nna/core";
import * as z from "zod";

import {
	createChangeStateSchemaFromShapeV0,
	addChangeStateSchemaToShape,
} from "../../entry.change-state";
import { EntryCommon } from "../../entry.common";
import { recipeModelSchema } from "../recipe.model";
import { SalaryEvolution } from "./salary-evolution";

/** Validation schema for the expense in {@link SalaryModel} */
export const salaryExpenseSchema = z.object({
	amount: EntryCommon.amountSchema,
	effective: z.boolean(),
});
/** The salary expense property */
export type SalaryExpense = z.infer<typeof salaryExpenseSchema>;

/** Zod Shape for {@link salaryModelSchema} */
export const salaryModelRawShape = {
	activityRate: EntryCommon.percentageSchema,
	annualGross: EntryCommon.amountSchema,
	annualNet: EntryCommon.amountSchema,
	dateStart: z.date().describe("Start date of this salary"),
	evolution: z.boolean().describe("Is there an evolution in this salary"),
	evolutionParam: SalaryEvolution.schema.describe(
		"Evolution parameters when enabled. To ignore when disabled",
	),

	expenseFormation: salaryExpenseSchema,
	expenseMeal: salaryExpenseSchema,
	expenseOther: salaryExpenseSchema,
	expenseOutside: salaryExpenseSchema,
	expenseTransport: salaryExpenseSchema,
} as const satisfies z.ZodRawShape;

/** Validation schema for {@link SalaryModel} */
export const salaryModelSchema = Model.schema.extend(
	addChangeStateSchemaToShape(
		createChangeStateSchemaFromShapeV0(salaryModelRawShape),
		{
			...salaryModelRawShape,
			fkRecipe: recipeModelSchema.shape._id,
		},
	),
);
/** The salary of a `Recipe` */
export type SalaryModel = z.infer<typeof salaryModelSchema>;
