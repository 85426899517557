import * as z from "zod";

/** Validation schema for {@link Customer} */
export const customerSingleSchema = z.enum(["CUSTOMER_1", "CUSTOMER_2"]);
/** Same as {@link Customer} but does not allow `BOTH` */
export type CustomerSingle = z.infer<typeof customerSingleSchema>;

/** Validation schema for {@link Customer} */
export const customerSchema = z.enum([...customerSingleSchema.options, "BOTH"]);
/**
 * The "link"/selector to the customer of the plan.
 * As a value inside entry content to avoid hundred of foreign key
 * 	that must be checked in the application.
 *
 * If the plan does not have a secondary customer, then `CUSTOMER_2` and `BOTH` can be ignored and considered as `CUSTOMER_1`
 */
export type Customer = z.infer<typeof customerSchema>;
