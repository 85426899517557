import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	childModelDTOSchema,
	childModelRawShape,
	childModelSchema,
} from "../child.model";

/** Validation schema for {@link ChildDto} */
export const childDtoSchema = childModelSchema;
export type ChildDto = z.infer<typeof childDtoSchema>;

/** Validation schema for {@link ChildCreateDto} */
export const childCreateDtoSchema = z.object(childModelRawShape).strict();
/** Dto to create a `recipe */
export type ChildCreateDto = z.infer<typeof childCreateDtoSchema>;

/** Validation schema for {@link ChildQueryDto} */
export const childQueryDtoSchema = createQueryObjectSchema(
	childModelDTOSchema,
	{
		coerce: true,
		strict: true,
	},
).strict();

/** DTO used to filter `child` */
export type ChildQueryDto = z.infer<typeof childQueryDtoSchema>;

/** Validation schema for {@link ChildQueryResultsDto} */
export const childQueryResultsDtoSchema =
	createQueryResultsSchema(childDtoSchema);
/** Results for `childs` from a query */
export type ChildQueryResultsDto = z.infer<typeof childQueryResultsDtoSchema>;

/** Validation schema for {@link ChildUpdateDto} */
export const childUpdateDtoSchema = z
	.object(childModelRawShape)
	.strict()
	.partial();
/** Dto to update a `child */
export type ChildUpdateDto = z.infer<typeof childUpdateDtoSchema>;
