import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	residenceModelRawShape,
	residenceModelSchema,
} from "../residence.model";

/** Validation schema for {@link ResidenceDto} */
export const residenceDtoSchema = residenceModelSchema;
export type ResidenceDto = z.infer<typeof residenceDtoSchema>;

/** Validation schema for {@link ResidenceCreateDto} */
export const residenceCreateDtoSchema = z
	.object(residenceModelRawShape)
	.strict();
/** Dto to create a `recipe */
export type ResidenceCreateDto = z.infer<typeof residenceCreateDtoSchema>;

/** Validation schema for {@link ResidenceQueryDto} */
export const residenceQueryDtoSchema = createQueryObjectSchema(
	residenceDtoSchema,
	{
		coerce: true,
		strict: true,
	},
).strict();

/** DTO used to filter `residence` */
export type ResidenceQueryDto = z.infer<typeof residenceQueryDtoSchema>;

/** Validation schema for {@link ResidenceQueryResultsDto} */
export const residenceQueryResultsDtoSchema =
	createQueryResultsSchema(residenceDtoSchema);
/** Results for `residences` from a query */
export type ResidenceQueryResultsDto = z.infer<
	typeof residenceQueryResultsDtoSchema
>;

/** Validation schema for {@link ResidenceUpdateDto} */
export const residenceUpdateDtoSchema = z
	.object(residenceModelRawShape)
	.strict()
	.partial();
/** Dto to update a `residence */
export type ResidenceUpdateDto = z.infer<typeof residenceUpdateDtoSchema>;
