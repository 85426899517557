import { CONTACT_HTTP_CONFIG, ContactHttp } from "~/common/contact";

import { getRequestMethodFromHttpMethod } from "./utils";
import { fetchRequest } from "../utils/utils";

const { routes } = CONTACT_HTTP_CONFIG;

/** Http client for `contact` feature */
export const ContactHttpClient: ContactHttp = {
	findAndCount: query =>
		fetchRequest({
			endpoint: routes.findAndCount.path({}),
			method: getRequestMethodFromHttpMethod(routes.findAndCount.method),
			query,
		}),
	findById: params =>
		fetchRequest({
			endpoint: routes.findById.path(params),
			method: getRequestMethodFromHttpMethod(routes.findById.method),
		}),
};
