import { createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { entryDtoSchema } from "./entry.dto";

/** Validation schema for {@link EntryQueryResultsDto} */
export const entryQueryResultsDtoSchema =
	createQueryResultsSchema(entryDtoSchema);
/** Results for `entry` from a query */
export type EntryQueryResultsDto = z.infer<typeof entryQueryResultsDtoSchema>;
