import { createQueryObjectSchema } from "@nna/core";
import * as z from "zod";

import { entryDtoSchema } from "./entry.dto";

/** Validation schema for {@link EntryQueryDto} */
export const entryQueryDtoSchema = createQueryObjectSchema(entryDtoSchema, {
	coerce: true,
	strict: true,
}).strict();

/** DTO used to filter `entry` */
export type EntryQueryDto = z.infer<typeof entryQueryDtoSchema>;
