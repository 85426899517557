import * as z from "zod";

import { createEditedSchema } from "./base-edited";

/** The type of this state */
export const TYPE = "UPDATED";

/**
 * Creates a validation schema for {@link Model}
 *
 * @param fields that are "actively" modified
 * @returns validation schema for {@link Model}
 */
export function createSchema<const F extends readonly string[]>(fields: F) {
	return createEditedSchema(TYPE, fields);
}

/**
 * Change state when a new content (on another entry) updates an existing one
 */
export type Model<F extends readonly string[]> = z.infer<
	ReturnType<typeof createSchema<F>>
>;
