import { HttpRoute } from "@nna/core";

import {
	CustomerCreateDto,
	CustomerDto,
	CustomerFindOneDto,
	CustomerQueryDto,
	CustomerQueryResultsDto,
	CustomerUpdateDto,
} from "./dtos";

/** Entrypoint route */
const root = HttpRoute.builder("customers");
const rootById = root.addSegment({
	param: "customerId",
	type: "param",
	validation: "number",
});

/** HTTP configuration for `customer` features */
export const CUSTOMER_HTTP_CONFIG = {
	routes: {
		findAndCount:
			root.get<
				(query: CustomerQueryDto) => Promise<CustomerQueryResultsDto>
			>(),
		findById: rootById.get<() => Promise<CustomerFindOneDto>>(),

		create: root.post<(body: CustomerCreateDto) => Promise<CustomerDto>>(),
		update: rootById.patch<
			(body: CustomerUpdateDto) => Promise<CustomerDto>
		>(),
	} satisfies HttpRoute.Definitions,
} as const;

/** HTTP specification for the `customer` feature */
export type CustomerHttp = HttpRoute.Handlers<
	typeof CUSTOMER_HTTP_CONFIG.routes
>;
