import styled from "@emotion/styled";

/**
 * A spring take remaining space in a flex layout
 *
 * It could be replaced by other style (eg: `justify-content: space-between`),
 * 	but it is sometime easier to keep all children "flat" and add a spring
 * 	rather than grouping some children inside another container
 */
export const Spring = styled.div`
	flex: 10;
`;
