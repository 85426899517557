import * as z from "zod";

/** The discrimination key for {@link createBaseSchema} */
export const DISCRIMINATION_KEY = "type";

/**
 * Create base schema for {@link ChangeState}
 *
 * @param type discriminated value
 * @param shape addition shape to add
 * @returns base schema
 */
export function createBaseSchema<
	const T extends string,
	const S extends z.ZodRawShape,
>(type: T, shape: S) {
	return z.object({ ...shape, [DISCRIMINATION_KEY]: z.literal(type) });
}

/** Base model from {@link createSchema} */
export type BaseModel<T extends string> = z.infer<
	ReturnType<typeof createBaseSchema<T, Record<never, never>>>
>;
