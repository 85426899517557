import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import {
	exceptionalExpenseModelRawShape,
	exceptionalExpenseModelSchema,
} from "../exceptional.expense.model";

/** Validation schema for {@link ExceptionalExpenseDto} */
export const exceptionalExpenseDtoSchema = exceptionalExpenseModelSchema;
export type ExceptionalExpenseDto = z.infer<typeof exceptionalExpenseDtoSchema>;

/** Validation schema for {@link ExceptionalExpenseCreateDto} */
export const exceptionalExpenseCreateDtoSchema = z
	.object(exceptionalExpenseModelRawShape)
	.strict();
/** Dto to create a `recipe */
export type ExceptionalExpenseCreateDto = z.infer<
	typeof exceptionalExpenseCreateDtoSchema
>;

/** Validation schema for {@link ExceptionalExpenseQueryDto} */
export const exceptionalExpenseQueryDtoSchema = createQueryObjectSchema(
	exceptionalExpenseDtoSchema,
	{
		coerce: true,
		strict: true,
	},
).strict();

/** DTO used to filter `exceptionalExpense` */
export type ExceptionalExpenseQueryDto = z.infer<
	typeof exceptionalExpenseQueryDtoSchema
>;

/** Validation schema for {@link ExceptionalExpenseQueryResultsDto} */
export const exceptionalExpenseQueryResultsDtoSchema = createQueryResultsSchema(
	exceptionalExpenseDtoSchema,
);
/** Results for `exceptionalExpenses` from a query */
export type ExceptionalExpenseQueryResultsDto = z.infer<
	typeof exceptionalExpenseQueryResultsDtoSchema
>;

/** Validation schema for {@link ExceptionalExpenseUpdateDto} */
export const exceptionalExpenseUpdateDtoSchema = z
	.object(exceptionalExpenseModelRawShape)
	.strict()
	.partial();
/** Dto to update a `exceptionalExpense */
export type ExceptionalExpenseUpdateDto = z.infer<
	typeof exceptionalExpenseUpdateDtoSchema
>;
