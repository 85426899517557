import { HttpRoute } from "@nna/core";

import type {
	ExceptionalCreateDto,
	ExceptionalDto,
	ExceptionalQueryDto,
	ExceptionalQueryResultsDto,
	ExceptionalUpdateDto,
} from "./dtos";
import { CreateExceptionalExpensesHttpDefinitions } from "./expense";
import { EntryHttpConfig } from "../../entry.http";

/**
 * Create routes definitions for `exceptional` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createExceptionalExpensesHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "exceptionalId",
		type: "param",
		validation: "number",
	});

	return {
		children: {
			expenses: CreateExceptionalExpensesHttpDefinitions(
				baseById.addSegment("expenses"),
			),
		},
		routes: {
			findAndCount:
				base.get<
					(
						query: ExceptionalQueryDto,
					) => Promise<ExceptionalQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<ExceptionalDto>>(),

			create: base.post<
				(body: ExceptionalCreateDto) => Promise<ExceptionalDto>
			>(),
			delete: baseById.delete<() => Promise<ExceptionalDto>>(),
			update: baseById.patch<
				(body: ExceptionalUpdateDto) => Promise<ExceptionalDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}
/** Full configuration for `Entry/Exceptional` features and children */
type ExceptionalHttpConfig = EntryHttpConfig["children"]["exceptional"];
/** HTTP specification for the `Entry/Exceptional` features */
export type ExceptionalHttp = HttpRoute.Handlers<
	ExceptionalHttpConfig["routes"]
>;
