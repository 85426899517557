import { KeepPropertiesOf } from "@nna/core";

import { SalaryExpense, SalaryModel } from "../salary.model";

/** Minimal type of {@link SalaryModel} with its expenses */
export type SalaryWithExpenses = KeepPropertiesOf<SalaryModel, SalaryExpense>;

/**
 * "Extracts" (get) all expenses of a salary
 *
 * @param salary to extract the expenses from
 * @returns an array of the expense
 */
export function extractExpenses<T extends SalaryWithExpenses>(salary: T) {
	return [
		salary.expenseFormation,
		salary.expenseMeal,
		salary.expenseOther,
		salary.expenseOutside,
		salary.expenseTransport,
	] satisfies SalaryExpense[];
}

/**
 * Calculates the sum of expenses when they are effective
 *
 * @param expenses to get the sum from
 * @returns the total sum from the expense
 */
export function calcTotalExpense(expenses: readonly SalaryExpense[]) {
	return expenses
		.map(expense => (expense.effective ? expense.amount : 0))
		.reduce((sum, expense) => sum + expense, 0);
}

/**
 * Same as {@link calcTotalExpense} but directly from a {@link SalaryWithExpenses}.
 * Does {@link extractExpenses} then {@link calcTotalExpense}.
 *
 * @param salary to extract and calculate the expense from
 * @returns the total sum from the expense
 */
export function calcTotalExpenseFromSalary<T extends SalaryWithExpenses>(
	salary: T,
) {
	return calcTotalExpense(extractExpenses(salary));
}
