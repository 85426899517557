import * as z from "zod";

import { createEditedSchema } from "./base-edited";

/** The type of this state */
export const TYPE = "REMOVED";

/**
 * Creates a validation schema for {@link Model}
 *
 * @param fields that were "actively" modified
 * @returns validation schema for {@link Model}
 */
export function createSchema<const F extends readonly string[]>(fields: F) {
	return createEditedSchema(TYPE, fields);
}

/**
 * Change state when a content has been removed (from an `UPDATED` state)
 * The modified fields stay in case of recovery
 */
export type Model<F extends readonly string[]> = z.infer<
	ReturnType<typeof createSchema<F>>
>;
