import { HttpMethod } from "@nna/core";

import { RequestMethod } from "../utils/utils";

/**
 * Get (converts) the defined {@link HttpMethod} to its {@link RequestMethod} counterpart
 *
 * @param method to "convert"
 * @returns the Request method enum value
 */
export function getRequestMethodFromHttpMethod(
	method: HttpMethod,
): RequestMethod {
	switch (method) {
		case "GET":
			return RequestMethod.GET;
		case "POST":
			return RequestMethod.POST;
		case "PATCH":
			return RequestMethod.PATCH;
		case "PUT":
			return RequestMethod.PUT;
		case "DELETE":
			return RequestMethod.DELETE;
	}
}
