import { USER_HTTP_CONFIG, UserHttp } from "~/common/user";

import { getRequestMethodFromHttpMethod } from "./utils";
import { fetchRequest } from "../utils/utils";

const { routes } = USER_HTTP_CONFIG;

/** Http client for `user` feature */
export const UserHttpClient: UserHttp = {
	findAndCount: query =>
		fetchRequest({
			endpoint: routes.findAndCount.path({}),
			method: getRequestMethodFromHttpMethod(routes.findAndCount.method),
			query,
		}),
	findById: params =>
		fetchRequest({
			endpoint: routes.findById.path(params),
			method: getRequestMethodFromHttpMethod(routes.findById.method),
		}),
};
