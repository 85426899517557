import * as emotion from "@emotion/react";
import styled from "@emotion/styled";
import Link, { LinkProps } from "next/link";

import { Icon, IconNameKey } from "../../ui-atoms/components/Icons/Icon";
import { theme } from "../styles/theme";

/** Name of the CSS var for its size */
export const NAV_ITEM_SIZE_CSS_VAR = "--nav-size";

/** Modes for {@link NavItemCss} */
export type NavItemMode = "active" | "hover-able" | "inactive";
/** Props for {@link NavItemCss} */
export interface NavItemCssProps {
	mode?: NavItemMode;
}
/** Generate css for "nav-item" */
export const NavItemCss = ({ mode }: NavItemCssProps) => emotion.css`
	display: flex;
	flex: 0 0 var(${NAV_ITEM_SIZE_CSS_VAR});
	justify-content: center;
	place-items: center center;
	transition: background linear 0.2s;

	${mode === "active" && `background: ${theme.palette.grey[20]};`}
	${mode === "hover-able" && `&:hover {background-color: ${theme.palette.grey[60]}; cursor: pointer;}`}
`;

/** A nav item that also is a {@link Link next-link} */
export const MainNavItemLink = styled(Link)<NavItemCssProps>`
	${NavItemCss}
`;

/** "Configuration" for a "regular NavItem" icon */
export const NAV_ICON_CONFIG = {
	colors: {
		active: theme.palette.primary[700],
		inactive: theme.palette.grey[40],
	},
	size: 24,
};

/** Props for {@link MainNavLink} */
export interface MainNavLinkProps {
	/** Is the link active */
	active: boolean;
	/** Icon of the link */
	icon: IconNameKey;
	/** Url ~= href */
	url: LinkProps["href"];
}
/** Main nav item with a link and an item */
export function MainNavLink(props: MainNavLinkProps) {
	const { active, icon, url: href, ...rest } = props;
	const { colors, size } = NAV_ICON_CONFIG;

	return (
		<MainNavItemLink
			{...rest}
			href={href}
			mode={active ? "active" : "hover-able"}
		>
			<Icon
				color={active ? colors.active : colors.inactive}
				name={icon}
				size={size}
			/>
		</MainNavItemLink>
	);
}
