import * as z from "zod";

/** Constraints applied to {@link amountSchema} (for SQL checks for example) */
export const AMOUNT_CONSTRAINTS = { gte: 0 };
/** Schema for an amount value */
export const amountSchema = z.number().int().gte(AMOUNT_CONSTRAINTS.gte);

/** Constraints applied to {@link percentageSchema} (for SQL checks for example) */
export const PERCENTAGE_CONSTRAINTS = { gte: 0, lte: 100 };

/** Schema for an (almost ?) unbounded percentage value */
export const percentageUnboundedSchema = z.number();
/** Schema for a percentage value */
export const percentageSchema = percentageUnboundedSchema
	.gte(PERCENTAGE_CONSTRAINTS.gte)
	.lte(PERCENTAGE_CONSTRAINTS.lte);
