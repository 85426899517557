import { HttpRoute } from "@nna/core";

import { UserDto, UserQueryDto, UserQueryResultsDto } from "./dtos";

/** Entrypoint route */
const root = HttpRoute.builder("users");
const rootById = root.addSegment({
	param: "userId",
	type: "param",
	validation: "number",
});

/** HTTP configuration for the `user` feature */
export const USER_HTTP_CONFIG = {
	routes: {
		findAndCount:
			root.get<(query: UserQueryDto) => Promise<UserQueryResultsDto>>(),
		findById: rootById.get<() => Promise<UserDto>>(),
	} satisfies HttpRoute.Definitions,
} as const;

/** HTTP specification for the `user` feature */
export type UserHttp = HttpRoute.Handlers<typeof USER_HTTP_CONFIG.routes>;
