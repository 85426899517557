import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { currentModelRawShape, currentModelSchema } from "../current.model";
import { currentExpenseDtoSchema } from "../expense";

/** Validation schema for {@link CurrentDto} */
export const currentDtoSchema = currentModelSchema.extend({
	expenses: z.array(currentExpenseDtoSchema),
});
export type CurrentDto = z.infer<typeof currentDtoSchema>;

/** Validation schema for {@link CurrentCreateDto} */
export const currentCreateDtoSchema = z.object(currentModelRawShape).strict();
/** Dto to create a `recipe */
export type CurrentCreateDto = z.infer<typeof currentCreateDtoSchema>;

/** Validation schema for {@link CurrentQueryDto} */
export const currentQueryDtoSchema = createQueryObjectSchema(currentDtoSchema, {
	coerce: true,
	strict: true,
}).strict();

/** DTO used to filter `current` */
export type CurrentQueryDto = z.infer<typeof currentQueryDtoSchema>;

/** Validation schema for {@link CurrentQueryResultsDto} */
export const currentQueryResultsDtoSchema =
	createQueryResultsSchema(currentDtoSchema);
/** Results for `currents` from a query */
export type CurrentQueryResultsDto = z.infer<
	typeof currentQueryResultsDtoSchema
>;

/** Validation schema for {@link CurrentUpdateDto} */
export const currentUpdateDtoSchema = z
	.object(currentModelRawShape)
	.strict()
	.partial();
/** Dto to update a `current */
export type CurrentUpdateDto = z.infer<typeof currentUpdateDtoSchema>;
