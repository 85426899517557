import { HttpRoute } from "@nna/core";

import { EntryFindOneDto } from "./dtos";
import { PLAN_HTTP_ROOT_BY_ID } from "../plan";
import { createCurrentExpensesHttpDefinitions } from "./expense/current";
import { createExceptionalExpensesHttpDefinitions } from "./expense/exceptional";
import { createPersonalHttpDefinitions } from "./personal";
import { createChildHttpDefinitions } from "./personal/child";
import { createResidenceHttpDefinitions } from "./personal/residence";
import { createRecipeHttpDefinitions } from "./recipe";

// TODO
/** Entrypoint route */
const root = PLAN_HTTP_ROOT_BY_ID.addSegment("entries");
const rootById = root.addSegment({
	param: "entryId",
	type: "param",
	validation: "number",
});

/**
 * HTTP configuration for `Entry` features
 * It is a "indirect-child" of `Plan`
 */
export const ENTRY_HTTP_CONFIG = {
	children: {
		children: createChildHttpDefinitions(rootById.addSegment("children")),

		current: createCurrentExpensesHttpDefinitions(
			rootById.addSegment("current"),
		),
		exceptional: createExceptionalExpensesHttpDefinitions(
			rootById.addSegment("exceptional"),
		),

		personals: createPersonalHttpDefinitions(
			rootById.addSegment("personals"),
		),
		recipes: createRecipeHttpDefinitions(rootById.addSegment("recipes")),
		residences: createResidenceHttpDefinitions(
			rootById.addSegment("residences"),
		),
	},
	routes: {
		// `findAndCount` not needed for v1
		findById: rootById.get<() => Promise<EntryFindOneDto>>(),
	} satisfies HttpRoute.Definitions,
} as const;

/** Full configuration for `Entry` features and children */
export type EntryHttpConfig = typeof ENTRY_HTTP_CONFIG;

/** HTTP specification for the `Entry` feature */
export type EntryHttp = HttpRoute.Handlers<EntryHttpConfig["routes"]>;
