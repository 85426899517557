import * as z from "zod";

import { entryModelSchema } from "../entry.model";

/** Validation schema for {@link EntryCreateDto} */
export const entryCreateDtoSchema = entryModelSchema
	.pick({
		// FOR V2 (only parent)
	})
	.strict();
/** Dto to create a `entry` */
export type EntryCreateDto = z.infer<typeof entryCreateDtoSchema>;
