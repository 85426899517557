import { AUTH_HTTP_CONFIG, AuthHttp } from "~/common/auth";

import { getRequestMethodFromHttpMethod } from "./utils";
import { fetchRequest } from "../utils/utils";

const { routes } = AUTH_HTTP_CONFIG;

export const AuthHttpClient: AuthHttp = {
	getProfile: () =>
		fetchRequest({
			endpoint: routes.getProfile.path({}),
			method: getRequestMethodFromHttpMethod(routes.getProfile.method),
		}),
	login: body =>
		fetchRequest({
			body,
			endpoint: routes.login.path({}),
			method: getRequestMethodFromHttpMethod(routes.login.method),
		}),
	logout: () =>
		fetchRequest({
			endpoint: routes.logout.path({}),
			method: getRequestMethodFromHttpMethod(routes.logout.method),
		}),
	passwordReset: body =>
		fetchRequest({
			body,
			endpoint: routes.passwordReset.path({}),
			method: getRequestMethodFromHttpMethod(routes.passwordReset.method),
		}),
	passwordSet: body =>
		fetchRequest({
			body,
			endpoint: routes.passwordSet.path({}),
			method: getRequestMethodFromHttpMethod(routes.passwordSet.method),
		}),
	refresh: body =>
		fetchRequest({
			body,
			endpoint: routes.refresh.path({}),
			method: getRequestMethodFromHttpMethod(routes.refresh.method),
		}),
};
