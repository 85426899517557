import { createQueryObjectSchema, createQueryResultsSchema } from "@nna/core";
import * as z from "zod";

import { personalModelRawShape, personalModelSchema } from "../personal.model";

/** Validation schema for {@link PersonalDto} */
export const personalDtoSchema = personalModelSchema;
export type PersonalDto = z.infer<typeof personalDtoSchema>;

/** Validation schema for {@link PersonalCreateDto} */
export const personalCreateDtoSchema = z.object(personalModelRawShape);
/** Dto to create a `personal */
export type PersonalCreateDto = z.infer<typeof personalCreateDtoSchema>;

/** Validation schema for {@link PersonalQueryDto} */
export const personalQueryDtoSchema = createQueryObjectSchema(
	personalDtoSchema,
	{
		coerce: true,
		strict: true,
	},
).strict();

/** DTO used to filter `personal` */
export type PersonalQueryDto = z.infer<typeof personalQueryDtoSchema>;

/** Validation schema for {@link PersonalQueryResultsDto} */
export const personalQueryResultsDtoSchema =
	createQueryResultsSchema(personalDtoSchema);
/** Results for `personals` from a query */
export type PersonalQueryResultsDto = z.infer<
	typeof personalQueryResultsDtoSchema
>;

/** Validation schema for {@link PersonalUpdateDto} */
export const personalUpdateDtoSchema = personalCreateDtoSchema.partial();
/** Dto to update a `personal */
export type PersonalUpdateDto = z.infer<typeof personalUpdateDtoSchema>;
