import { CUSTOMER_HTTP_CONFIG, CustomerHttp } from "~/common/customer";

import { getRequestMethodFromHttpMethod } from "./utils";
import { fetchRequest } from "../utils/utils";

const { routes } = CUSTOMER_HTTP_CONFIG;

/** Http client for `customer` feature */
export const CustomerHttpClient: CustomerHttp = {
	findAndCount: query =>
		fetchRequest({
			endpoint: routes.findAndCount.path({}),
			method: getRequestMethodFromHttpMethod(routes.findAndCount.method),
			query,
		}),
	findById: params =>
		fetchRequest({
			endpoint: routes.findById.path(params),
			method: getRequestMethodFromHttpMethod(routes.findById.method),
		}),

	create: body =>
		fetchRequest({
			body,
			endpoint: routes.create.path({}),
			method: getRequestMethodFromHttpMethod(routes.create.method),
		}),
	update: (params, body) =>
		fetchRequest({
			body,
			endpoint: routes.update.path(params),
			method: getRequestMethodFromHttpMethod(routes.update.method),
		}),
};
