import * as z from "zod";

import * as States from "./states";
import { BaseModel, DISCRIMINATION_KEY } from "./states/base";
import { BaseEditedModel } from "./states/base-edited";

/**
 * Creates a validation schema for {@link Model}
 *
 * @param fields that may be updated in a `edited` state
 * @returns validation schema for {@link Model}
 */
export function createSchema<const F extends readonly string[]>(fields: F) {
	return z.discriminatedUnion(DISCRIMINATION_KEY, [
		States.Added.schema,
		States.Deleted.schema,
		States.Updated.createSchema(fields),
		States.Removed.createSchema(fields),
	]);
}
/** Output type of {@link createSchema} */
export type Schema<T extends readonly string[]> = ReturnType<
	typeof createSchema<T>
>;

/** The state of change between `entry` models */
export type Model<T extends readonly string[]> = z.output<Schema<T>>;

/** Known type for {@link Model} */
export type Type = Model<[]>[typeof DISCRIMINATION_KEY];
/** List of available types for {@link Model} */
export const TYPES: readonly Type[] = createSchema([]).options.map(
	({ shape }) => shape[DISCRIMINATION_KEY].value,
);

/** Common model for all Options */
export type CommonModel = BaseModel<Type>;

/** Types for editable models */
export type TypeEditable =
	| typeof States.Removed.TYPE
	| typeof States.Updated.TYPE;
/** Common edited model for all known Options */
export type CommonEditedModel<F extends readonly string[]> = BaseEditedModel<
	TypeEditable,
	F
>;
