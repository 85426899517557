import { Model } from "@nna/core";
import * as z from "zod";

import { createBaseSchema } from "./base";

/**
 * Creates a validation schema for a state that refers to another element.
 *
 * @param type discriminated value
 * @param fields that are edited
 * @returns a validation schema
 */
export function createEditedSchema<
	const T extends string,
	const F extends readonly string[],
>(type: T, fields: F) {
	// @ts-expect-error -- Not the zod way, but the easiest here for any subtype
	const enumSchema = z.enum(fields as never) as z.ZodEnum<F>;

	return createBaseSchema(type, {
		fields: z.array(enumSchema).describe("Fields that have been edited"),
		fkParent: Model.schema.shape._id,
	});
}

/** Edited model from {@link createEditedSchema} */
export type BaseEditedModel<
	T extends string,
	F extends readonly string[],
> = z.infer<ReturnType<typeof createEditedSchema<T, F>>>;
