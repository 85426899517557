import { HttpRoute } from "@nna/core";

import {
	PlanCreateDto,
	PlanDto,
	PlanQueryDto,
	PlanQueryResultsDto,
} from "./dtos";

/** Entrypoint route */
const root = HttpRoute.builder("plans");
/** Entrypoint root for {@link PlanDto} by an id */
export const PLAN_HTTP_ROOT_BY_ID = root.addSegment({
	param: "planId",
	type: "param",
	validation: "number",
});

/** HTTP configuration for `Plan` features */
export const PLAN_HTTP_CONFIG = {
	children: {
		// `Entry` is an "indirect-child"; it is a "module" on its own
	},
	routes: {
		findAndCount:
			root.get<(query: PlanQueryDto) => Promise<PlanQueryResultsDto>>(),
		findById: PLAN_HTTP_ROOT_BY_ID.get<() => Promise<PlanDto>>(),

		create: root.post<(body: PlanCreateDto) => Promise<PlanDto>>(),
		delete: PLAN_HTTP_ROOT_BY_ID.delete<() => Promise<PlanDto>>(),
		// TODO FG-17
		// update: rootById.get<
		// 	(body: PlanUpdateDto) => Promise<PlanDto>
		// >(),
	} satisfies HttpRoute.Definitions,
} as const;

/** HTTP specification for the `Plan` feature */
export type PlanHttp = HttpRoute.Handlers<typeof PLAN_HTTP_CONFIG.routes>;
