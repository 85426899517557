import styled from "@emotion/styled";

import * as Layout from "../layout";
import { NAV_ITEM_SIZE_CSS_VAR } from "../main-layout/MainLayoutNavItem";
import { theme } from "../styles/theme";

/** Container for {@link Header} and {@link Content} */
export const Container = styled(Layout.Columned)`
	flex: 1;
`;

/** Main content for the most interaction */
export const Content = styled(Layout.Columned)`
	flex: 1;
`;

/** Header of the main-wrapper */
export const Header = styled.div`
	align-items: center;
	background: ${theme.getCssVar("palette-grey-20")};
	border-bottom: 1px solid ${theme.getCssVar("palette-grey-40")};
	display: flex;
	flex: 0 0 var(${NAV_ITEM_SIZE_CSS_VAR});
	gap: 16px;
	justify-content: space-between;
	padding: 0 24px;
`;
