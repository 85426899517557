import { PLAN_HTTP_CONFIG, PlanHttp } from "~/common/plan";

import { getRequestMethodFromHttpMethod } from "./utils";
import { fetchRequest } from "../utils/utils";

const { routes } = PLAN_HTTP_CONFIG;

/** Http client for `plan` features */
export const PlanHttpClient: PlanHttp = {
	findAndCount: query =>
		fetchRequest({
			endpoint: routes.findAndCount.path({}),
			method: getRequestMethodFromHttpMethod(routes.findAndCount.method),
			query,
		}),
	findById: params =>
		fetchRequest({
			endpoint: routes.findById.path(params),
			method: getRequestMethodFromHttpMethod(routes.findById.method),
		}),

	create: body =>
		fetchRequest({
			body,
			endpoint: routes.create.path({}),
			method: getRequestMethodFromHttpMethod(routes.create.method),
		}),
	delete: params =>
		fetchRequest({
			endpoint: routes.delete.path(params),
			method: getRequestMethodFromHttpMethod(routes.delete.method),
		}),
};
