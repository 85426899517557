import * as z from "zod";

import { createBaseSchema } from "./base";

/** The type of this state */
export const TYPE = "DELETED";

/** Validation schema for {@link Model} */
export const schema = createBaseSchema(TYPE, {});

/** Change state when has been added and then deleted (but kept for history) */
export type Model = z.infer<typeof schema>;
