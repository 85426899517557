import { HttpRoute } from "@nna/core";

import type {
	CurrentCreateDto,
	CurrentDto,
	CurrentQueryDto,
	CurrentQueryResultsDto,
	CurrentUpdateDto,
} from "./dtos";
import { CreateCurrentExpensesHttpDefinitions } from "./expense";
import { EntryHttpConfig } from "../../entry.http";

/**
 * Create routes definitions for `current` features
 *
 * @param base of the HttpRoute builder
 * @returns route definitions
 */
export function createCurrentExpensesHttpDefinitions<
	const BASE extends HttpRoute.BuilderAny,
>(base: BASE) {
	const baseById = base.addSegment({
		param: "currentId",
		type: "param",
		validation: "number",
	});

	return {
		children: {
			expenses: CreateCurrentExpensesHttpDefinitions(
				baseById.addSegment("expenses"),
			),
		},
		routes: {
			findAndCount:
				base.get<
					(query: CurrentQueryDto) => Promise<CurrentQueryResultsDto>
				>(),
			findById: baseById.get<() => Promise<CurrentDto>>(),

			create: base.post<
				(body: CurrentCreateDto) => Promise<CurrentDto>
			>(),
			delete: baseById.delete<() => Promise<CurrentDto>>(),
			update: baseById.patch<
				(body: CurrentUpdateDto) => Promise<CurrentDto>
			>(),
		} satisfies HttpRoute.Definitions,
	} as const;
}
/** Full configuration for `Entry/Current` features and children */
type CurrentHttpConfig = EntryHttpConfig["children"]["current"];
/** HTTP specification for the `Entry/Current` features */
export type CurrentHttp = HttpRoute.Handlers<CurrentHttpConfig["routes"]>;
