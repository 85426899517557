import styled from "@emotion/styled";

import { PeopleAvatar, PeopleAvatarProps } from "./PeopleAvatar";
import { textBody } from "../../ui-layout/styles/textStyles";

const Container = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 2px;
`;

const Name = styled.span`
	${textBody}
`;

/** Props for {@link PeopleNameWithAvatar} */
export type PeopleNameWithAvatarProps = PeopleAvatarProps;
/** Component that show a people avatar and its name */
export function PeopleNameWithAvatar(props: PeopleNameWithAvatarProps) {
	const { name } = props;

	return (
		<Container data-testid="people/name-with-avatar">
			<PeopleAvatar {...props} />

			<Name>
				{name.first} {name.last}
			</Name>
		</Container>
	);
}
