import * as dateFns from "date-fns";

import { Entry } from "../../..";

/**
 * Returns the current residence index (if any), amongst a list of (unordered) residences.
 * The current residence is the one with the most recent `from` date that is before now.
 * It orders the residences by DESC in case it's not yet done (without affecting the original array)
 *
 * @param residences array of {@link Entry.ResidenceDto}
 * @returns the current residence index
 */
export function findCurrentResidenceIndex(
	residences: readonly Entry.ResidenceDto[],
) {
	const now = new Date();

	// Sort DESC the list and find the current residence
	const sorted = [...residences].sort((a, b) =>
		dateFns.compareDesc(a.from, b.from),
	);
	const currentResidence = sorted.find(residence =>
		dateFns.isBefore(residence.from, now),
	);

	// Return its index position in the original list
	return currentResidence
		? residences.findIndex(
				residence => residence._id === currentResidence._id,
			)
		: -1;
}

/**
 * Returns a reordered list of residences, with the current one first.
 * We copy the original residences array so that we don't mutate it.
 *
 * @param residences array of {@link Entry.ResidenceDto}
 * @returns the reordered residences list
 */
export function getReorderedResidences(residences: Entry.ResidenceDto[]) {
	const currentResidenceIndex = findCurrentResidenceIndex(residences);

	if (currentResidenceIndex === -1) {
		return residences;
	}
	const residencesCopy = [...residences];
	residencesCopy.unshift(residencesCopy.splice(currentResidenceIndex, 1)[0]);
	return residencesCopy;
}
