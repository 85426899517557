import styled from "@emotion/styled";
import { PeopleName } from "~/common/people";

import { theme } from "../../ui-layout/styles/theme";

/** Managed sizes for the avatar (all in px) */
const PEOPLE_AVATAR_SIZES = {
	big: { font: 18, size: 40 },
	large: { font: 15, size: 32 },
	medium: { font: 12, size: 24 },
	small: { font: 7.5, size: 20 },
	tiny: { font: 6, size: 16 },
} satisfies Record<
	string,
	{
		/** Size of the font */
		font: number;
		/** Size of the "avatar" (icon/image) */
		size: number;
	}
>;

/** Managed size for an avatar */
export type PeopleAvatarSize = keyof typeof PEOPLE_AVATAR_SIZES;

const Avatar = styled.div<{ size: PeopleAvatarSize }>`
	--avatar-size: ${({ size }) => PEOPLE_AVATAR_SIZES[size].size}px;
	--avatar-font-size: ${({ size }) => PEOPLE_AVATAR_SIZES[size].font}px;

	align-items: center;
	background-color: ${theme.getCssVar("palette-grey-40")};
	border-radius: 100vw;
	display: flex;
	max-height: var(--avatar-size);
	max-width: var(--avatar-size);
	min-height: var(--avatar-size);
	min-width: var(--avatar-size);
	place-content: center center;

	span {
		color: ${theme.getCssVar("palette-grey-60")};
		font-size: var(--avatar-font-size);
		text-transform: uppercase;
	}
`;

/** Props for {@link PeopleAvatar} */
export interface PeopleAvatarProps {
	/** Name of the people to generate the avatar from (when no image) */
	name: PeopleName;
	/**
	 * Size of the avatar
	 *
	 * @default 'medium'
	 */
	size?: PeopleAvatarSize;

	// avatar image ?
}
/** Component that show a people avatar (image or icon with abbreviation) */
export function PeopleAvatar(props: PeopleAvatarProps) {
	const { name, size = "medium" } = props;

	const abbreviation = [name.first, name.last]
		.map(name => name.slice(0, 1))
		.join("");

	return (
		<Avatar data-testid="people/avatar" size={size}>
			<span data-testid="people/avatar/abbr">{abbreviation}</span>
		</Avatar>
	);
}
