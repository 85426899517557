import * as z from "zod";

import { createBaseSchema } from "./base";

/** The type of this state */
export const TYPE = "ADDED";

/** Validation schema for {@link Model} */
export const schema = createBaseSchema(TYPE, {});

/** Change state when a content is added */
export type Model = z.infer<typeof schema>;
